<template>
    <p class="py-8 text-sm text-gray-500 font-light">
        Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłącznie informacyjny.
        Ostateczne warunki umowy zależą od oceny zdolności finansowanej oraz finansowanego przedmiotu i zostaną
        wskazane w odpowiedniej umowie, po złożeniu wniosku.<br/>
        Partnerem oferty jest VEHIS Finanse sp. z o.o. która przygotowała ofertę finansowania<br/>
        Administratorem Pani/Pana danych jest VEHIS Finanse sp. z o.o. oraz VEHIS sp. z o.o. będący
        współadministratorami danych. Pełna treść klauzuli informacyjnej dostępna jest pod adresem: <a target="_blank" href="https://vehis.pl/files/docs/informacja_administrtora_danych_osobowych_formularz_kontaktowy.pdf">
            <span class="text-blue-600 underline" style="color: blue !important;">link</span>
        </a>
    </p>
</template>