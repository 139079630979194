<script setup>

</script>

<template>
    <footer class="pb-16">
        <div class="text-lg mb-6">
            <p class="font-normal">
                VEHIS sp. z o.o.
                <span class="font-extralight">
                    al. Murckowska 14c, 40-265 Katowice
                </span>
                <span class="font-extralight">
                    KRS: 000778463, NIP: 1132995753
                </span>
            </p>
        </div>
    </footer>
</template>
